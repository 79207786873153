import {useParams} from "react-router-dom";
import Typography from "@material-ui/core/Typography";
import {
    AppBar, Avatar, Box, Button, Checkbox, Container, Drawer, Fade, FormControlLabel, IconButton,
    makeStyles, Paper, Popper, Switch,
    Toolbar
} from "@material-ui/core";
import firebase from "firebase";
import {ChatPanel} from "./Chat/ChatPanel";
import {useDocumentData} from "react-firebase-hooks/firestore";
import Loading from "./Loading";
import React, {useState} from 'react';
import ChatIcon from '@material-ui/icons/Chat';
import {useAuthState} from "react-firebase-hooks/auth";
import {VideoPanel} from "./VideoPanel"

const useStyles = makeStyles((theme) => ({
    root: {
        display: 'flex',
        flexGrow: 1,
    },
    appBar: {
        zIndex: theme.zIndex.drawer + 1,
    },
    drawerContainer: {
        overflow: 'auto',
    },
    content: {
        flexGrow: 1,
        padding: theme.spacing(3),
    },
    menuButton: {
        marginRight: theme.spacing(1),
    },
    title: {
        flexGrow: 1,
    },
    small: {
        width: theme.spacing(3),
        height: theme.spacing(3),
    },
}));

export default function EventPage() {

    let {id} = useParams();
    const classes = useStyles();

    const [event, eventLoading, error] = useDocumentData(
        firebase.firestore().doc('events/' + id),
        {
            snapshotListenOptions: {includeMetadataChanges: true},
        }
    );

    const [user, loading, _] = useAuthState(firebase.auth());
    const [openChat, setOpenChat] = useState(true);
    const [openProfile, setOpenProfile] = useState(false);
    const [anchorEl, setAnchorEl] = React.useState(null);
    const [placement, setPlacement] = React.useState();

    const handleClick = (newPlacement) => (event) => {
        setAnchorEl(event.currentTarget);
        setOpenChat(false);
        setOpenProfile((prev) => placement !== newPlacement || !prev);
        setPlacement(newPlacement);
    };

    if (eventLoading) {
        return <Loading/>
    } else if (event) {
        return <div className={classes.root}>

            <Container width={300}>
                <p>.</p>
                <p>.</p>
                <VideoPanel event={id}/>
            </Container>

            <AppBar position="fixed" className={classes.appBar}>
                <Toolbar variant={"dense"}>
                    <Typography variant="h6" className={classes.title}>
                        Event | {event.name}
                    </Typography>

                    <IconButton color={openChat ? "text.primary" : "text.disabled"}className={classes.margin}  onClick={handleClick('bottom-end')}>
                        <Avatar className={classes.small} src={user.photoURL} />
                    </IconButton>
                    <Popper open={openProfile} anchorEl={anchorEl} placement={placement} transition>
                        {({ TransitionProps }) => (
                            <Fade {...TransitionProps} timeout={350}>
                                <Paper>
                                    <Box p={1}>
                                        <Box p={1}>
                                            <Typography className={classes.typography}>Signed in as {user.displayName}</Typography>
                                        </Box>
                                        <Button variant="contained" color="Primary" onClick={() => firebase.auth().signOut()}>
                                            Sign Out
                                        </Button>
                                    </Box>
                                </Paper>
                            </Fade>
                        )}
                    </Popper>

                    <IconButton  color={openChat ? "text.primary" : "text.disabled"}className={classes.margin} onClick={() => {setOpenChat(!openChat)}} >
                        <ChatIcon fontSize="medium" />
                    </IconButton>
                </Toolbar>
            </AppBar>
            <ChatPanel event={id} open={openChat}/>
        </div>
    }
}
