import {useCollection, useCollectionData} from "react-firebase-hooks/firestore";
import firebase from "firebase";
import Loading from "./Loading";
import Box from "@material-ui/core/Box";
import {
    Button,
    Divider,
    GridList,
    GridListTile,
    GridListTileBar,
    IconButton,
    makeStyles,
    Paper
} from "@material-ui/core";
import Typography from "@material-ui/core/Typography";
import PlayCircleFilledIcon from "@material-ui/icons/PlayCircleFilled";
import React from "react";
import {useHistory} from "react-router-dom";
import {relativeTime} from "human-date"

const useStyles = makeStyles((theme) => ({
    mainBox: {
        // backgroundImage: 'url(https://images.unsplash.com/photo-1445498059992-f5a276e1218d?ixid=MXwxMjA3fDB8MHxwaG90by1wYWdlfHx8fGVufDB8fHw%3D&ixlib=rb-1.2.1&auto=format&fit=crop&w=2551&q=80)',
        // backgroundRepeat: 'no-repeat',
        // backgroundColor: theme.palette.type === 'light' ? theme.palette.grey[50] : theme.palette.grey[900],
        // backgroundSize: 'cover',
        // backgroundPosition: 'center',
        width: '100vw',
        height: '100vh',
        // backdropFilter: "blur(10px)"
    },
    gridList: {
        width: "100%",
        height: 450,
        // overflow: ""
    },
}));

export let MainPage = function() {

    const classes = useStyles();
    const history = useHistory();

    const [events, eventsLoading, error] = useCollection(
        firebase.firestore().collection('events'),
        {
            snapshotListenOptions: { includeMetadataChanges: true },
        }
    );

    if(eventsLoading) {
        return <Loading/>
    } else if(events) {

        const tileData = events.docs.map(doc => {

            return {
                id: doc.id,
                img :  doc.data().photoURL,
                title: doc.data().name,
                timestamp: relativeTime(doc.data().timestamp.toDate()),
                occupants: 0
            };
        });

        return <Box className={classes.mainBox}>
            <Box display="flex" height="100%" width="100%" justifyContent="center" alignItems="center">
                <Paper>
                    <Box p={2} width={800}>
                        <GridList cellHeight={180} className={classes.gridList}>
                            {tileData.map((tile) => (
                                <GridListTile key={tile.img}>
                                    <img src={tile.img} alt={tile.title} />
                                    <GridListTileBar
                                        title={tile.title}
                                        subtitle={tile.timestamp} actionIcon={
                                            <IconButton aria-label={`Go to ${tile.title}`}
                                                        className={classes.icon}
                                                        onClick={() => history.push("events/" + tile.id)}>
                                                <PlayCircleFilledIcon />
                                            </IconButton>
                                        }
                                    />
                                </GridListTile>
                            ))}
                        </GridList>
                    </Box>
                </Paper>
            </Box>
        </Box>
    }
}