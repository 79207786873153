import {useDocumentData} from "react-firebase-hooks/firestore";
import firebase from "firebase";
import {
    Avatar,
    Box,
    Divider,
    ListItem,
    ListItemAvatar,
    ListItemText,
} from "@material-ui/core";
import {emojify} from 'react-emojione';
import {Fragment} from "react";

export let ChatMessage = function (props) {

    const [user, userLoading] = useDocumentData(
        firebase.firestore().doc('users/' + props.user));

    const emojiOptions = {
        convertShortnames: true,
        convertUnicode: true,
        convertAscii: true,
        output: 'unicode',
    };

    if (!userLoading) {
        return  <Fragment>
            <ListItem alignItems="flex-start">
                <ListItemAvatar>
                    <Avatar src={user.photoURL} />
                </ListItemAvatar>
                <ListItemText
                    primary={user.displayName}
                    secondary={
                        <Fragment>
                            {emojify(props.message, emojiOptions)}
                        </Fragment>
                    }
                />
            </ListItem>
            <Divider variant="inset" component="li" />
        </Fragment>
    } else {
        return <Box/>
    }
}