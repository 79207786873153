import React from 'react';
import CssBaseline from '@material-ui/core/CssBaseline';
import Paper from '@material-ui/core/Paper';
import Box from '@material-ui/core/Box';
import Grid from '@material-ui/core/Grid';
import Typography from '@material-ui/core/Typography';
import { makeStyles } from '@material-ui/core/styles';
import GoogleButton from 'react-google-button'
import {useAuthState} from "react-firebase-hooks/auth";
import firebase from "firebase";
import {Redirect, useLocation} from "react-router-dom";
import Loading from "./Loading";

const useStyles = makeStyles((theme) => ({
    root: {
        height: '100vh',
    },
    image: {
        // backgroundImage: 'url(https://source.unsplash.com/featured/?computer)',
        // backgroundRepeat: 'no-repeat',
        // backgroundColor: theme.palette.type === 'light' ? theme.palette.grey[50] : theme.palette.grey[900],
        backgroundSize: 'cover',
        backgroundPosition: 'center',
    },
    paper: {
        margin: theme.spacing(8, 4),
        display: 'flex',
        flexDirection: 'column',
        alignItems: 'center',
    },
    alignItemsAndJustifyContent: {
        width: '100%',
        height: '100%',
        display: 'flex',
        alignItems: 'center',
        justifyContent: 'center',
    },
}));

const provider = new firebase.auth.GoogleAuthProvider();

// login page with the ability to re-direct back to your intended page before bouncing
export default function LoginPage() {
    const classes = useStyles();
    const [user, loading, error] = useAuthState(firebase.auth());
    const location = useLocation();

    const updateUserRecord = function() {
        const user = firebase.auth().currentUser;
        firebase.firestore().collection("users").doc(user.uid).set({
            displayName : user.displayName,
            photoURL: user.photoURL
        });
    }

    const { from } = location.state || { from: { pathname: "/" } };

    if(loading) {
        return <Loading/>
    } else if(user) {
        updateUserRecord();
        return <Redirect to={from}/>
    } else {
        return (
            <Grid container component="main" className={classes.root}>
                <CssBaseline />
                <Grid item xs={false} sm={4} md={7} className={classes.image} />
                <Grid item xs={12} sm={8} md={5} component={Paper} elevation={6} square>
                    <Box display="flex" height="100%" width="100%" justifyContent="center" alignItems="center">
                        <Box m={5}>
                            <Typography component="h1" variant="h3">
                                Welcome to Morpheus
                            </Typography>

                            <Box mt={3}>
                                <Typography component="p">
                                    Please sign in with your Google account to get started.
                                </Typography>
                            </Box>

                            <Box mt={3}>
                                <GoogleButton onClick={() => firebase.auth().signInWithPopup(provider)}  />
                            </Box>
                        </Box>
                    </Box>
                </Grid>
            </Grid>
        );
    }
}