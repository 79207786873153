import {useCollectionData} from "react-firebase-hooks/firestore";
import firebase from "firebase";
import {ChatMessage} from "./ChatMessage";
import {
    Avatar,
    Box, Divider,
    Drawer, List, ListItem, ListItemAvatar, ListItemText,
    makeStyles, Toolbar,
} from "@material-ui/core";
import {ChatBox} from "./ChatBox";

const drawerWidth = 350;

const useStyles = makeStyles((theme) => ({
    drawer: {
        width: drawerWidth,
        flexShrink: 0,
        // overflow: "hidden"
    },
    drawerPaper: {
        width: drawerWidth,
        overflow: "hidden",
        backgroundColor: "rgba(0, 0, 0, 0.3)",
        borderWidth: 0,
        backdropFilter: "blur(5px)",
    },
    drawerContainer: {
        overflow: 'auto',
    },
    appBar: {
        zIndex: theme.zIndex.drawer + 1,
    },
}));

export let ChatPanel = function (props) {
    const classes = useStyles();

    const [chatMessages, chatMessagesLoading, error] = useCollectionData(
        firebase.firestore().collection('events/' + props.event + '/messages').orderBy("timestamp", "desc").limit(20),
        {
            snapshotListenOptions: {includeMetadataChanges: true},
        }
    );

    if (!chatMessagesLoading) {
        const chatMessageBoxes = chatMessages.map(chatMessage => {
            return <ChatMessage user={chatMessage.user} message={chatMessage.message}/>
        });

        return <Drawer
            className={classes.drawer}
            variant="persistent"
            anchor="right"
            open={props.open}
            classes={{
                paper: classes.drawerPaper,
            }}>
            <Toolbar variant="dense"/>
            <Box p={1}>
                <ChatBox event={props.event}/>
            </Box>
            <Box className={classes.drawerContainer}>
                <List className={classes.root}>
                    {chatMessageBoxes}
                </List>
            </Box>
        </Drawer>

    } else {
        return <p>Foo</p>
    }

}