import React from 'react';
import ReactDOM from 'react-dom';
import './index.css';
import App from './App';

import {createMuiTheme, MuiThemeProvider} from "@material-ui/core";
import grey from "@material-ui/core/colors/grey";

const theme = createMuiTheme({
    palette: {
        type: "dark",
        primary: {
            main: grey[800],
        },
        secondary: {
            main: grey[900],
        },
    },
});

ReactDOM.render(
    <React.StrictMode>
        <MuiThemeProvider theme={theme}>
            <App/>
        </MuiThemeProvider>
    </React.StrictMode>,
    document.getElementById('root')
);