import firebase from "firebase";
import Peer from 'peerjs';
import React, {useEffect, useRef} from "react";
import {useCollection, useCollectionData} from "react-firebase-hooks/firestore";
import Box from "@material-ui/core/Box";
import {
    Button,
    Card,
    CardActionArea,
    CardActions,
    CardContent,
    CardMedia,
    Container, makeStyles,
    Paper,
    Toolbar
} from "@material-ui/core";
import {relativeTime} from "human-date";
import Typography from "@material-ui/core/Typography";

const peer = new Peer();

peer.on('open', (peerId) => {
    console.log("Got Peer ID", peerId);
});

export let mystream = null;

let getMyStream = function() {
    return mystream;
}

console.log("GETTING USER MEDIA")



let vidPeers = [];

let vids = {};

let renderVideo = (peerId, stream) => {

    // if(vids[peerId] !== undefined) {
    //     // document.getElementById("videos").removeChild(vids[peerId]);
    // }

    if(vidPeers.includes(stream)) {
        return;
    }

    vidPeers.push(stream);

    // if(vidPeers.includes(peerId)) {
    //     console.log("ALREADY FOUND PEER ", peerId)
    //     return;
    // }

    // console.log("NEW PEER VIDEO", peerId)
    //
    // vidPeers.push(peerId);

    let video = document.createElement("video");
    video.height = 150;
    video.width = 200;
    video.autoplay = true;
    video.srcObject = stream;
    document.getElementById("videos").appendChild(video);
    // vids[peerId] = video;
};

let done = false;
let renderLocalVideo = (stream) => {

    if(done) {
        return;
    }
    done = true;

    console.log("LOCAL VIDEO")
    let video = document.createElement("video");
    video.height = 150;
    video.width = 200;
    video.autoplay = true;
    video.srcObject = stream;
    video.defaultMuted = true;
    document.getElementById("videos").appendChild(video);
    console.log(video.muted);
    video.muted = true;
    console.log("MUTING")
};

navigator.mediaDevices.getUserMedia({video: true, audio: true})
.then((stream) => {
    mystream = stream;
})
.catch((err) => {
    console.error('Failed to get local stream', err);
});

// navigator.mediaDevices.getDisplayMedia({video: true, audio: true})
//     .then((stream) => {
//         console.log("USER MEDIA", stream)
//         mystream = stream;
//         renderLocalVideo(mystream)
//     })
//     .catch((err) => {
//         console.error('Failed to get local stream', err);
//     });

// Handle incoming data connection
// peer.on('connection', (conn) => {
//     console.log('incoming peer connection!');
//     conn.on('data', (data) => {
//         console.log(`received: ${data}`);
//     });
//     conn.on('open', () => {
//         conn.send('hello!');
//     });
// });

peer.on('call', (call) => {
    call.answer();
    call.on('stream', (remoteStream) => {
        renderVideo("BARRY", remoteStream);
    });
});

const advertisePeerData = function(eventId) {
    const user = firebase.auth().currentUser;
    console.log("Advertising peer ", peer.id, " to event ", eventId);
    firebase.firestore().collection("users").doc(user.uid).update({
        currentRoom: {
            timestamp: firebase.firestore.FieldValue.serverTimestamp(),
            event: eventId,
            peer: peer.id,
        }
    });
}

let updated = false;

let peers = [];
const connectToPeer = function(peerId){
    console.log(peers)

    if(peers.includes(peerId)) {
        console.log("NOT CONNECTING TO EXISTING PEER", peerId)
        return;
    }

    console.log("CONNECTING TO PEER", peerId)

    peers.push(peerId);

    // let conn = peer.connect(peerId);
    // conn.on('data', (data) => {
    //     console.log(`received: ${data}`);
    // });
    // conn.on('open', () => {
    //     conn.send('hi!');
    // });

    console.log("AGOT STREEEM!")


    // navigator.mediaDevices.getUserMedia({video: true, audio: true}, (stream) => {

        // console.log("GOT USER MEDIA TO INITIATE CONNECTIOON")

        // console.log("mystream", getMyStream());

        let ccc = peer.call(peerId, getMyStream());

        if(ccc) {
            // ccc.on('stream', renderVideo);
        } else {
            console.log("PEER WAS NULL")
        }
        //     ccc.on('open', () => {
        //         console.log("OPENED CONNECTION");
        //     });

        // } else {
        //     console.log(ccc);
        //     console.log("NULL CALL ON CONNECTIOn");
        // }
    //
    //
    // }, (err) => {
    //     console.error('Failed to get local stream', err);
    // });



    // navigator.mediaDevices.getUserMedia({video: true, audio: true})
    //     .then((stream) => {
    //
    //     })
    //     .catch((err) => {
    //         console.log('Failed to get local stream', err);
    //     });
}

const useStyles = makeStyles((theme) => ({

}));


export let VideoPanel = function(props) {

    const styles = useStyles();

    let currentUser = firebase.auth().currentUser.uid;


    //firebase.firestore.FieldValue.serverTimestamp()


    const [activeUsers, activeUsersLoading, error] = useCollection(
        firebase.firestore().collection('users').where('currentRoom.event', '==', props.event),
        {
            snapshotListenOptions: {includeMetadataChanges: true},
        }
    );

    useEffect(() => {
        // if(!updated) {
        //     updated = true;
            advertisePeerData(props.event);
        // }
    }, [props.event, peer.id]);

    useEffect(() => {
        console.log("REFRESH")
    });

    if(error) {

    }
    else if(activeUsersLoading) {
        return <p>loading</p>
    } else if(activeUsers) {

        for(const user in activeUsers) {

        }

        console.log(activeUsers.docs.length);

        var ONE_HOUR = 1 * 60 * 1000; /* ms */


        renderLocalVideo(mystream)

        let barry = activeUsers.docs.filter(user => user.id != currentUser &&
            ((new Date) - user.data().currentRoom.timestamp.toDate()) < ONE_HOUR).map(user => {


                connectToPeer(user.data().currentRoom.peer);

           // return  <Paper>
           //     <Typography variant="p" color="White" >
           //         {user.data().displayName} (last seen: {relativeTime(user.data().currentRoom.timestamp.toDate())})
           //         <Button onClick={() => {
           //             connectToPeer(user.data().currentRoom.peer)
           //         }}>Connect</Button>
           //     </Typography>
           //     <p>
           //
           //     </p>
           // </Paper>
        });



        // console.log("MYSTREAM", mystream);
        return <div>
            {/*{barry}*/}
        {/*<VideoCall srcObject={mystream} />*/}
        </div>
    } else if(error) {
        console.log(error);
        return <p>watttt</p>
    } else {
        return <p>MAGICAL MYSTERY</p>
    }
}

function VideoCall(props) {
    return <Paper width={400}>
        <Video srcObject={mystream} width={400} height={200}/>
    </Paper>
}

function Video({srcObject, ...props}) {
    const refVideo = useRef(null)

    useEffect(() => {
        if (!refVideo.current) return
        console.log("SETTTIN SRC OBJECT", srcObject)
        refVideo.current.srcObject = srcObject
    }, [srcObject])

    return <video ref={refVideo} autoPlay muted {...props}/>
}


const videoWall = {
    userId : {
        videoStream: "",

    }
}
