import {Box, Button, TextField} from "@material-ui/core";
import firebase from "firebase";
import {useState} from "react";

const sendEventChatMessage = (event, message) => {
    let user = firebase.auth().currentUser.uid;

    firebase.firestore()
        .collection("events")
        .doc(event)
        .collection("messages")
        .add({
            user: user,
            message: message,
            timestamp: firebase.firestore.FieldValue.serverTimestamp()
        });
}

export let ChatBox = function (props) {
    const [value, setValue] = useState('');

    const send = function () {
        sendEventChatMessage(props.event, value);
        setValue("");
    }

    return <Box>
        <TextField
            label="Enter Message"
            multiline
            rowsMax={6}
            fullWidth={true}
            value = {value}
            onChange={event => setValue(event.target.value)}
            onKeyDown={event => {if(event.which === 13) {send(); event.preventDefault();}}}
            variant="outlined"
        />
    </Box>
}