import {useAuthState} from 'react-firebase-hooks/auth';
import firebase from "firebase";
import Login from "./LoginPage";
import {
    BrowserRouter as Router,
    Switch,
    Route,
    Redirect} from "react-router-dom";
import React from "react";
import Loading from "./Loading";
import {MainPage} from "./MainPage";
import EventPage from "./EventPage";

const firebaseConfig = {
    apiKey: "AIzaSyCE6BlLgK6bAWRFubop0Wc-_-IrxkQajQg",
    authDomain: "venator-unsepulcher-3029873.firebaseapp.com",
    databaseURL: "https://venator-unsepulcher-3029873.firebaseio.com",
    projectId: "venator-unsepulcher-3029873",
    storageBucket: "venator-unsepulcher-3029873.appspot.com",
    messagingSenderId: "496683047294",
    appId: "1:496683047294:web:d944c8edb372aa5ef27b2f",
    measurementId: "G-MK8DWLH5RP"
};

// wrapper here allows the site to work during dev live-reloading
if (firebase.apps.length === 0) {
    firebase.initializeApp(firebaseConfig);
}



function App() {
    return <Router>
        <Switch>
            <Route path="/login">
                <Login/>
            </Route>
            <RequireLoginRoute path="/events/:id" children={<EventPage/>}/>
            <RequireLoginRoute path="/" children={<MainPage/>}/>
        </Switch>
    </Router>
}

// Helper wrapper for react router that ensures user is logged in, otherwise kicks to login screen
function RequireLoginRoute({children, ...rest}) {
    const [user, loading, error] = useAuthState(firebase.auth());

    if (loading) {
        return <Loading/>
    } else {
        return <Route
            {...rest}
            render={({location}) =>
                !loading && user ? (
                    children
                ) : (
                    <Redirect
                        to={{
                            pathname: "/login",
                            state: {from: location}
                        }}
                    />
                )
            }
        />
    }
}

export default App;
